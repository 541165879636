:root {
  --theme1: #02af90;
  --theme2: #fdd400;
  --background: #f2f2f2;
  --form: #fff;
}

.join-now-btn {
  
}

.one-line-txt {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.my-scroll::-webkit-scrollbar {
  display: none;
}
.my-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.fb-btn {
  background-color: #3c5997;
  width: 100%;
  margin-top: 10px;
  color: white;
  height: 45px;
  border: 0px;
  border-radius: 5px;
  font-size: 16px;
}

.google-btn {
  background-color: orange;
  width: 100%;
  margin-top: 10px;
  color: white;
  height: 45px;
  border: 0px;
  border-radius: 5px;
}

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: var(--background);
  height: 90vh;
}

.login {
  display: flex;
  margin-top: 40px;
  border-radius: 10px;
  padding: 30px;
  flex-direction: column;
  width: 50%;
  background-color: var(--form);
  align-self: center;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navigation {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  /* position: relative; */
  background-color: transparent;
  border: 0px;
  position: fixed;
  top: 0;
  z-index: 100;
  box-shadow: 0px 0.5px 8.0px gray;
}

.card {
  /* box-shadow: 0px 0.5px 8.0px gray; */
}

.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}

.navigation-menu {
  margin-left: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}

.navigation-menu ul {
  display: flex;
  flex: 1;
  padding: 0;
}

.navigation-menu li {
  align-self: center;
  list-style-type: none;
  margin: 0 10px;
  margin-top: 20px;
  font-size: 13pt;
  font-weight: bold;
}

.navigation-menu li:hover {
  opacity: 0.9;
}

.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
  color: #fff;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  /* background-color: #f2f2f2; */
  opacity: 0.5;
}

.join-as {
  background-color: white;
  border-radius: 5px;
}

.login-btn {
  font-size: 14pt;
  margin-top: 10px;
  height: 45px;
  background-color: var(--theme1);
  border: 0px;
  border-radius: 5px;
  color: white;
}

.hollow-btn {
  font-size: 14pt;
  margin-top: 10px;
  height: 45px;
  border: 0px solid var(--theme1);
  background-color: transparent;
  border-radius: 5px;
  color: var(--theme1);
}

.forgot-pass {
  font-size: 13pt;
  margin-top: 10px;
  background-color: transparent;
  height: 45px;
  border: 0px;
  color: var(--theme1);
}

.hollow-btn1 {
  font-size: 12pt;
  background-color: transparent;
  height: 35px;
  border-radius: 5px;
  border: 1px solid var(--theme1);
  padding-left: 12px;
  padding-right: 12px;
  color: var(--theme1);
}

.join-as button {
  color: var(--theme1);
  font-weight: bold;
  padding: 8px;
  border: 0px;
  background-color: transparent;
  text-shadow: 1px 1px 1px 1px black;
}

.picker {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 5px;
  font-size: 12pt;
}

.search-btn {
  display: flex;
  flex: 1;
  border: 0px;
  border-radius: 5px;
  min-width: 150px;
  color: white;
  background-color: var(--theme1);
  height: 45px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.search-btn1 {
  display: flex;
  border: 0px;
  border-radius: 5px;
  min-width: 150px;
  color: white;
  background-color: var(--theme1);
  height: 45px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.home-panel {
  width: 80%;
}

.filter_box {
  display: flex;
  overflow: hidden;
  border: 0px solid lightgray;
  position: relative;
  padding: 0px 0;
  border-radius: 5px;
  height: 45px;
}

.filter_box:after {
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  right: 20px;
  z-index: 98;
}

.select_box {
  display: flex;
  flex: 4;
  overflow: hidden;
  border: 1px solid lightgray;
  position: relative;
  padding: 5px 0;
  border-radius: 5px;
  min-width: 400px;
  height: 45px;
}

.select_box:after {
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  right: 10px;
  content: "";
  z-index: 98;
}

.select_box select {
  border: 0;
  position: relative;
  z-index: 99;
  background: none;
  width: 98%;
}

.select_box1 {
  display: flex;
  flex: 4;
  overflow: hidden;
  border: 1px solid lightgray;
  position: relative;
  padding: 5px 0;
  border-radius: 5px;
  min-width: 200px;
  height: 45px;
}

.select_box1:after {
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  right: 10px;
  content: "";
  z-index: 98;
}

.select_box1 select {
  border: 0;
  position: relative;
  z-index: 99;
  background: none;
  width: 98%;
}

.cat-tab {
  display: flex;
  border-radius: 10px;
  padding: 15px;
  background-color: var(--theme1);
  color: white;
  min-width: 220px;
  box-shadow: 1px 1px 1px 1px lightgray;
}

/* .cat-tab:hover {
  opacity: 0.8;
} */

.cat-tab1 {
  display: flex;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  color: black;
  min-width: 220px;
  box-shadow: 1px 1px 1px 1px lightgray;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.cat-tab1:hover {
  opacity: 0.8;
}

.icon-tab {
  display: flex;
  border-radius: 10px;
  padding: 10px;
  background-color: black;
  color: white;
  min-width: 180px;
  box-shadow: 1px 1px 1px 1px lightgray;
}

.facebook-login {
  display: flex;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  background-color: #3c5997;
  color: white;
  font-size: 13pt;
  align-self: center;
  align-items: center;
  justify-content: center;
  height: 45px;
}

.google-login {
  display: flex;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  background-color: #ea4336;
  color: white;
  font-size: 13pt;
  align-self: center;
  align-items: center;
  justify-content: center;
  height: 45px;
}


.pro-item {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 350px;
}

.pro-item img {
  width: 350px;
  height: 200px;
  object-fit: cover;
}

.home-label {
  margin-top: 20px;
  margin-left: 20px;
  font-weight: bold;
  font-size: 20pt;
}

.my-title {
  font-size: 20pt;
}

.step-parent {
  display: flex;
  flex-direction: column;
  min-width: 220px;
}

.step {
  display: flex;
  align-self: center;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--theme1);
  background-color: transparent;
  color: var(--theme1);
  font-weight: bold;
  font-size: 26pt;
}

.step-txt {
  margin-top: 10px;
  align-self: center;
  color: black;
  font-size: 16pt;
  text-align: center;
  max-width: 320px;
}

.join-panel {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 40px;
  justify-content: space-between;
  align-items: center;
}

.join-panel-img {
  width: 400px;
  height: 300px;
  margin-right: 25px;
  margin-left: 25px;
  margin-top: 40px;
}

.industry-img {
  width: 300px;
  height: 300px;
  margin-right: 25px;
  margin-left: 25px;
  margin-top: 40px;
}

.vendor-img {
  width: 250px;
  height: 250px;
  margin-right: 25px;
  margin-left: 25px;
  padding-top: 0px;
  object-fit: contain;
}

.save-btn {
  font-size: 14pt;
}

.save-btn:hover {
  opacity: 0.5;
}

.vendor-logo {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.vendor-logo-big {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  object-fit: cover;
}

.footer {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 20px;
  background-color: #f2f2f2;
  flex: 1;
}

.footer-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 220px;
}

.footer-col1 {
  align-items: flex-start;
}

.footer a {
  text-decoration: none;
  font-size: 13pt;
  color: gray;
}

.footer div {
  text-decoration: none;
  font-size: 13pt;
}

.show-more-less {
  font-size: 16px;
}

.my-line {
  width: 100%;
  height: 1px;
  background-color: lightgray;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tab-item {
  color: var(--theme1);
  margin-top: 5px;
  margin-right: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid var(--theme1);
  font-size: 16px;
  border-radius: 5px;
  border-radius: 20px;
}

.tab-item1 {
  color: black;
  margin-top: 5px;
  margin-right: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid lightgray;
  background-color: transparent;
  font-size: 16px;
  border-radius: 30px;
}

.tab-item1-sel {
  color: white;
  margin-top: 5px;
  margin-right: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid var(--theme1);
  background-color: var(--theme1);
  font-size: 16px;
  border-radius: 30px;
}

.tab-item2 {
  display: flex;
  flex: 1;
  min-width: 300px;
  justify-content: center;
  color: black;
  margin-top: 10px;
  margin-right: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid lightgray;
  background-color: transparent;
  font-size: 16px;
  border-radius: 10px;
}

.tab-item2-sel {
  display: flex;
  flex: 1;
  min-width: 300px;
  justify-content: center;
  color: white;
  margin-top: 10px;
  margin-right: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid var(--theme1);
  background-color: var(--theme1);
  font-size: 16px;
  border-radius: 10px;
}

.vendor-info {
  display: flex;
}

.vendor-btns {
  align-items: flex-end;
  justify-content: flex-start;
}

.vendor-item {
  align-items: flex-start;
  justify-content: flex-start;
}

.vendor-view-profile {
  align-self: flex-start,
}

.vendor-results {
  align-items: center;
  justify-content: flex-end;
}

.dummy-panel {
  display: flex;
  flex: 1;
}

.get-qoute {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-left: 20px;
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  min-width: 300px;
}

.get-qoute div {
  color: 'white';
}

div.sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
}

.reverse-view {
  flex-direction: row-reverse;
}

.quote-popup-parent {
  visibility: hidden;
  height: 0px;
  width: 0px;
  overflow: hidden;
}

.quote-top {
  border-top: 10px solid var(--theme1);
}

.option-label {
  font-size: 16px;
  font-weight: bold;
  margin-top: 30px;
}

.row {
  display: flex;
  flex-direction: row;
}

.hover-effect {
  opacity: 1;
}

.hover-effect:hover {
  opacity: 0.7;
}

.card-hover {
  background-color: #f6f6f6;
}

.card-hover:hover {
  background-color: var(--background);
}

.hover-effect1 {
  border: 0px solid white;
  border-left-width: 2px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  background-color: transparent;
}

.hover-effect1:hover {
  border: 0px solid var(--theme1);
  border-left-width: 2px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  background-color: #FAFAFA;
}

.hover-effect2 {
  border: 0px solid white;
  border-left-width: 2px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  background-color: transparent;
}

.hover-effect2-sel {
  border: 0px solid var(--theme1);
  border-left-width: 2px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  background-color: #FAFAFA;
}

.col {
  display: flex;
  flex-direction: column;
}

.title-buttons {
  margin-right: 20px;
}

.choose-service {
  font-size: 26px;
}

.tab-btn1 {
  background-color: white;
  border: 1px solid var(--theme1);
  width: 150px;
  height: 40px;
  color: var(--theme1);
  font-size: 16px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.tab-btn1-sel {
  background-color: var(--theme1);
  border: 0px;
  width: 150px;
  height: 40px;
  color: white;
  font-size: 16px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.tab-btn2 {
  background-color: white;
  border: 1px solid var(--theme1);
  width: 150px;
  height: 40px;
  color: var(--theme1);
  font-size: 16px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.tab-btn2-sel {
  background-color: var(--theme1);
  border: 0px;
  width: 150px;
  height: 40px;
  color: white;
  font-size: 16px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.tab-btn3 {
  background-color: white;
  border: 1px solid var(--theme1);
  width: 150px;
  height: 40px;
  color: var(--theme1);
  font-size: 16px;
}

.tab-btn3-sel {
  background-color: var(--theme1);
  border: 0px;
  width: 150px;
  height: 40px;
  color: white;
  font-size: 16px;
}

.edit-profile-buttons {
  flex-direction: row;
}

.hide-on-mobile {
  display: flex;
}

.job-item {
  align-items: flex-start;
  justify-content: flex-start;
}

.margin-left-mobile {
  margin-left: 0px;
}

.padding-right-mobile {
  padding-right: 0px;
}

@media screen and (max-width: 1200px) {
  .edit-profile-buttons {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {

  .footer-col {
    min-width: 260px;
  }

  .step-parent {
    min-width: 260px;
  }

  .cat-tab {
    min-width: 260px;
  }

  .padding-right-mobile {
    padding-right: 20px;
  }

  .margin-left-mobile {
    margin-left: 15px;
  }

  .hide-on-mobile {
    display: none;
  }

  .edit-profile-buttons {
    flex-direction: column;
  }

  .choose-service {
    font-size: 20px;
  }

  .title-buttons {
    margin-right: 20px;
  }

  .quote-popup-parent {
    visibility: visible;
    height: auto;
    width: auto;
  }

  div.sticky {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0;
  }

  .footer-col1 {
    align-items: center;
  }

  .get-qoute {
    margin-left: 0px;
    margin-top: 10px;
    width: 95%;
  }

  .dummy-panel {
    display: flex;
    flex: 0;
  }

  .vendor-results {
    justify-content: flex-start;
  }

  .vendor-view-profile {
    align-self: center;
  }

  .vendor-item {
    align-items: center;
    justify-content: center;
  }

  .vendor-btns {
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    align-self: center;
  }

  .vendor-info {
    margin-top: 10px;
  }

  .login {
    width: 95%;
  }

  .home-panel {
    width: 95%;
  }

  .hamburger {
    display: block;
  }

  /* .navigation-menu ul {
    display: none;
  } */
  .navigation-menu ul {
    position: absolute;
    top: 60px;
    left: 0;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid black;
    display: none;
    z-index: 10;
  }

  .navigation-menu li {
    text-align: center;
    margin: 0;
    background-color: var(--theme1);
    margin-top: 0px;
  }

  .navigation-menu li a {
    color: white;
    width: 100%;
    padding: 0.5rem 0;
  }

  .navigation-menu li:hover {
    opacity: 1;
  }

  .navigation-menu.expanded ul {
    display: block;
  }

  .join-as {
    background-color: white;
    border-radius: 0px;
  }

  .join-as button {
    color: white;
    width: 100%;
    padding: 0.5rem 0;
    border-radius: 0px;
  }
}